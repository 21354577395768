export const commentItems = [
  {
    title: "Zoomerr",
    description:
      "Non risus viverra enim, quis. Eget vitae arcu vivamus sit tellus, viverra turpis lorem. Varius a turpis urna id porttitor.",
    profileImage: "profileOne",
    name: "Hellen Jummy",
    job: "Team Lead",
  },
  {
    title: "Shells",
    description:
      "Aliquet ridiculus mi porta habitant vulputate rhoncus, mattis amet enim. Sit purus venenatis velit semper lectus sed ornare quam nulla.",
    profileImage: "profileTwo",
    name: "Hellena John",
    job: "Co-founder",
  },
  {
    title: "ArtVenue",
    description:
      "A eget sed posuere dui risus habitasse mauris. Venenatis aliquet id ultrices a lacus. Pretium vehicula pretium posuere justo sed.",
    profileImage: "profileThree",
    name: "David Oshodi",
    job: "Manager",
  },
  {
    title: "Zoomerr",
    description:
      "Non risus viverra enim, quis. Eget vitae arcu vivamus sit tellus, viverra turpis lorem. Varius a turpis urna id porttitor.",
    profileImage: "profileOne",
    name: "Hellen Jummy",
    job: "Team Lead",
  },
  {
    title: "Shells",
    description:
      "Aliquet ridiculus mi porta habitant vulputate rhoncus, mattis amet enim. Sit purus venenatis velit semper lectus sed ornare quam nulla.",
    profileImage: "profileTwo",
    name: "Hellena John",
    job: "Co-founder",
  },
  {
    title: "ArtVenue",
    description:
      "A eget sed posuere dui risus habitasse mauris. Venenatis aliquet id ultrices a lacus. Pretium vehicula pretium posuere justo sed.",
    profileImage: "profileThree",
    name: "David Oshodi",
    job: "Manager",
  },
];
