export const footerItemList = [
  ["Product", "Pricing", "Overview", "Browse", "Accessibility", "Five"],
  [
    "Solutions",
    "Brainstorming",
    "Ideation",
    "Wireframing",
    "Research",
    "Design",
  ],
  [
    "Support",
    "Contact Us",
    "Developers",
    "Documentation",
    "Integrations",
    "Reports",
  ],
];
