export const serviceItems = [
  {
    name: "Nibh viverra",
    description:
      "Sit bibendum donec dolor fames neque vulputate non sit aliquam. Consequat turpis natoque leo, massa. ",
    image: "service-one",
  },
  {
    name: "Cursus amet",
    description:
      "Sit bibendum donec dolor fames neque vulputate non sit aliquam. Consequat turpis natoque leo, massa. ",
    image: "service-two",
  },
  {
    name: "Ipsum fermentum",
    description:
      "Sit bibendum donec dolor fames neque vulputate non sit aliquam. Consequat turpis natoque leo, massa. ",
    image: "service-three",
  },
];
