export const productItems = [
  {
    name: "Title",
    description:
      "Egestas elit dui scelerisque ut eu purus aliquam vitae habitasse.",
    image: "product-one",
  },
  {
    name: "Title",
    description:
      "Egestas elit dui scelerisque ut eu purus aliquam vitae habitasse.",
    image: "product-two",
  },
  {
    name: "Title",
    description:
      "Egestas elit dui scelerisque ut eu purus aliquam vitae habitasse.",
    image: "product-three",
  },
];
